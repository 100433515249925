<template>
          <b-tabs>
            <!-- Tab Carga de Archivos -->

            <b-tab title="Sku MMPP">
              <section id="pf-ajustes-sku">
                <ag-grid-table :configUrl="config" :dataUrl="data" :editUrl="edit" @getRowsLoaded="OnSizeColumns"></ag-grid-table>
              </section>
            </b-tab>
            <b-tab title="Sku aditivos">
              <section id="pf-restricciones-aditivos">
                <ag-grid-table :configUrl="config_aditivos" :dataUrl="data_aditivos" :editUrl="edit_aditivos" @getRowsLoaded="OnSizeColumns"></ag-grid-table>
              </section>
            </b-tab>
          </b-tabs>



</template>
<script>
import {
VBPopover,
VBTooltip,
BTabs,

} from "bootstrap-vue";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';

export default {
  data() {
    return {

      config: useApiServices.ajustesRestriccionesSkuConfig,
      data: useApiServices.ajustesRestriccionesSkuData,
      edit: useApiServices.ajustesRestriccionesSkuEdit,

      config_aditivos: useApiServices.ajustesRestriccionesSkuAditivosConfig,
      data_aditivos: useApiServices.ajustesRestriccionesSkuAditivosData,
      edit_aditivos: useApiServices.ajustesRestriccionesAditivosSkuEdit,

    };
  },
  components: {
    VBPopover,
    VBTooltip,
    AgGridTable,
    BTabs
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

  },

  methods: {
    OnSizeColumns(gridApi, gridColumnApi, params) {
      gridColumnApi.autoSizeColumns(['meta_data.producto', 'meta_data.materia_prima', 'meta_data.materia_prima_stock'], true);
    },

  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

